<template>
  <div :class="cssClasses">
    <VasionDocumentTemplateIconLarge />
    <div class="form-submitted">
      Form Submitted
    </div>
    <div class="thank-you">
      Thank you for using Vasion
    </div>
  </div>
</template>

<script>

export default {
  name: 'SubmissionComplete',
  computed: {
    isSignedIn() { return this.$store.state.common.isSignedIn },
    cssClasses() {
      let classes = [ 'submit-complete-container' ]
      if(this.isSignedIn)
        classes = [...classes, 'is-signed-in', 'vasion-calc-height']
      else
        classes = [...classes, 'not-signed-in']

      return classes.join(' ')
    }
  }
}

</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .submit-complete-container {
    display: flex;
    background-color: $grey-75;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .is-signed-in {
    width: 100%;
  }

  .not-signed-in {
    width: 100vw;
    height: 100vh;
  }

  .form-submitted {
    font-weight: 700;
    font-size: 3rem;
    margin-top: 3rem;
  }

  .thank-you {
    font-weight: 400;
    font-size: 2rem;
    margin-top: 3rem;
    color: $grey-500;
  }
</style>
